
import React from "react"

import TageditorPage from "../../components/negosyonow/controls/tageditor";


const TagPage = ({location}) => {
	return <TageditorPage
				location={location}
				allowadd={true}
			/>
}


export default TagPage;
