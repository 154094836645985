
import React, {useState} from "react"
import Layout from "../../layout"

import WebappsForm from "../../webapps/form"

const ENTITYID="nntag"

const NNTAGID_BRAND=1;
const NNTAGID_PROMO=99;

const searchFields = [
	{"label":"Name", "dbfield": "nntag_name", "type": "text", "filtertype": "textbox"},
	{"label":"Image", "dbfield": "nntag_image", "type": "image", "filtertype": "image"},
	//{"label":"Banner", "dbfield": "nntag_bannerimage", "type": "image", "filtertype": "image"},
	{"label":"Writeup", "dbfield": "nntag_description", "type": "text", "filterype": "textbox"}
];

const classificationpickerfields = [
	{"label":"Name", "dbfield": "nntagalias_fullname", "type": "text", "filtertype": "textbox"}
];

const classificationpickerfilter = [
	{"field":"nntagalias_parent", "operation": "=", "value": 0},
	{"field":"nntagalias_official", "operation": "=", "value": 1},
	{"field":"nntagalias_active", "operation": "=", "value": 1},
	{"field":"nntagalias_id", "operation": "<>", "value": NNTAGID_PROMO}
];

const newFields = [
	[
		{"label":"Name", "field": "nntag_name", "value": "", "input": "textbox", "mode": "unique"},
		{"label":"Classification", "field": "nntagalias_name", "value": "", "input": "picker", "mode":"required", "pickerfields": classificationpickerfields, "pickerfilters": classificationpickerfilter}
	]
];

const formFields = [
	[
		{"label":"Name", "field": "nntag_name", "value": "", "input": "textbox", "mode": "unique"},
		{"label":"Classification", "field": "nntagalias_name", "value": "", "input": "picker", "mode":"readonly"}
	]
];



const formROFields = [
	[
		{"label":"Name", "field": "nntag_name", "value": "", "input": "textbox", "mode": "readonly"},
		{"label":"Classification", "field": "nntagalias_name", "value": "", "input": "picker", "mode":"readonly"}
	]
];


const formTailFields = [
	[
		{"label":"Webpage", "field": "nntag_url", "value": "", "input": "url", "mode": "readonly", "urlprefix":"https://negosyonow.com/"},
		{"label":"Image", "field": "nntag_image", "value": "", "input": "image", "mode": "normal", "imagemode":"fit", "imagewidth":500, "imageheight":400, "imageformat":"jpg", "filehost": "cdn.ngnw.ph", "filelocation":"images/tagimage/uploads", "uploadtype":"https"},
		{"label":"Banner", "field": "nntag_bannerimage", "value": "", "input": "image", "mode": "normal", "imagemode":"fit", "imagewidth":1200, "imageheight":480, "imageformat":"jpg", "filehost": "cdn.ngnw.ph", "filelocation":"images/tagbanner/uploads", "uploadtype":"https"},
		{"label":"Writeup", "field": "nntag_description", "value": "", "input": "textarea", "mode": "normal"}
	]
];


const TageditorPage = ({location, allowadd}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");

	return <Layout fullPath={location.pathname}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			privatePage={true} usegatedcontent={true}>
			<WebappsForm
				location={location}
				allowadd={allowadd}
				allowdelete={false}
				pagetitle={pagetitle}
				mobilerowtitlefield={["nntag_name"]}
				searchFields={searchFields}
				editFields={allowadd?formFields.concat(formTailFields):formROFields.concat(formTailFields)}
				newformFields={allowadd?newFields.concat(formTailFields):[]}
				userSearchFilter={[
					{"field":"nntagalias_id", "operation":"<>", "value": NNTAGID_PROMO},
					{"field":"nntagalias_id", "operation":"<>", "value": NNTAGID_BRAND},
					{"field":"nntag_official", "operation":"<>", "value": 0},
					{"field":"nntag_url", "operation":"<>", "value": ""}
				]}
				entity={ENTITYID}
				token={token} />
		</Layout>
}


export default TageditorPage;
